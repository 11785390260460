import { RangeDate, RangeNum } from 'types'
import axios, { ICommonListRes, IResData } from 'utils/axios'
import { Currency } from 'utils/currency'
export interface IRoundListReq {
    page?: number
    limit?: number
    partnerId?: string[]
    app: string[]
    gameId?: string[]
    currency?: Array<keyof typeof Currency>
    startedAt: RangeDate
    finishedAt?: RangeDate
    totalBetAmount?: RangeNum
    totalWinAmount?: RangeNum
    playerId?: string[]
    userId?: string[]
    roundId?: string[]
}

export interface IRoundItem {
    partner?: string
    partner_id?: string
    app?: string
    user_id?: string
    player_id?: string
    game_id?: string
    round_id?: string
    currency?: string
    win_amount?: string
    bet_amount?: string
    started_at?: string
    finished_at?: string
    detail?: string
}

type RoundRes = IResData<ICommonListRes<IRoundItem[]>>
export const getRoundList = (params: IRoundListReq): Promise<RoundRes> => {
    return axios.post<IRoundListReq, RoundRes>(
        '/merchant/get-round-list',
        params
    )
}
